// src/App.js
import { useState, useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    // Snake game initialization and rendering logic will go here
    setupSnakeGame();
  }, []);

  function setupSnakeGame() {
    const canvas = document.getElementById('snakeGameCanvas');
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  
    const snakeSize = 10;
    let snake = [];
    const initialLength = 5;  // Initial number of segments
    for (let i = initialLength; i >= 0; i--) {
      snake.push({ x: 150 + i * snakeSize, y: 150 });
    }
    let snakeDir = 'right';
    let gameSpeed = 100;
  
    function drawSnake() {
      ctx.fillStyle = 'green';
      snake.forEach(part => {
        ctx.fillRect(part.x, part.y, snakeSize, snakeSize);
        ctx.strokeRect(part.x, part.y, snakeSize, snakeSize);
      });
    }
  
    function moveSnake() {
      let head = {x: snake[0].x, y: snake[0].y};
  
      if (head.x >= canvas.width - snakeSize && snakeDir === 'right') {
        snakeDir = 'down';  // Change direction if hitting the right boundary
      } else if (head.x <= 0 && snakeDir === 'left') {
        snakeDir = 'up';  // Change direction if hitting the left boundary
      } else if (head.y >= canvas.height - snakeSize && snakeDir === 'down') {
        snakeDir = 'left';  // Change direction if hitting the bottom boundary
      } else if (head.y <= 0 && snakeDir === 'up') {
        snakeDir = 'right';  // Change direction if hitting the top boundary
      }
  
      switch (snakeDir) {
        case 'right':
          head.x += snakeSize;
          break;
        case 'left':
          head.x -= snakeSize;
          break;
        case 'up':
          head.y -= snakeSize;
          break;
        case 'down':
          head.y += snakeSize;
          break;
      }
  
      snake.unshift(head);
      snake.pop();
    }
  
    function changeDirection() {
      const dirs = ['left', 'right', 'up', 'down'];
      // Prevent reversing direction immediately
      const oppositeDir = {
        left: 'right',
        right: 'left',
        up: 'down',
        down: 'up'
      };
      let newDir = dirs[Math.floor(Math.random() * dirs.length)];
      while (newDir === oppositeDir[snakeDir]) {
        newDir = dirs[Math.floor(Math.random() * dirs.length)];
      }
      snakeDir = newDir;
    }
  
    function updateGame() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawSnake();
      moveSnake();
      setTimeout(updateGame, gameSpeed);
    }
  
    setInterval(changeDirection, 2000);
    updateGame();
  }
  
  
  const [isGlitching, setGlitching] = useState(false);

  useEffect(() => {
    const triggerGlitch = () => {
      const nextGlitchDelay = Math.random() * 5000; // Random delay between 0 to 5000 milliseconds
      setTimeout(() => {
        setGlitching(true);
        setTimeout(() => {
          setGlitching(false);
        }, 1000); // Glitch duration of 1 second
      }, nextGlitchDelay);
    };    

    const intervalId = setInterval(triggerGlitch, 3000); // Check to trigger glitch approximately every 6 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const handleStartClick = () => {
    window.open('https://www.linkedin.com/in/vgondarev', '_blank');
  };

  return (
    <div>

    <div className="App">
      <canvas id="snakeGameCanvas" className="snake-canvas"></canvas>

      <header>
        <h1 className={isGlitching ? 'glitched' : ''}>Vladimir Gondarev</h1>
        <p>Software Developer & Solution Architect</p>
      </header>
      <main>
        <h2>Experienced in:</h2>
        <ul className="services">
          <li>Cloud Adoption & Migration Strategies</li>
          <li>Infrastructure as Code (IaC)</li>
          <li>DevOps and Kubernetes Consulting</li>
          <li>Backend and Frontend Development</li>

        </ul>
      </main>
      <footer>
        Reach out via LinkedIn: <a href="https://www.linkedin.com/in/vgondarev" target="_blank" rel="noopener noreferrer">Vladimir Gondarev</a><span className='margin-left-15 custom-cursor'></span>                        
      </footer>      
    </div>
    <div className="start-button" onClick={handleStartClick}>PRESS START BUTTON</div>
    </div>
  );
}

export default App;
